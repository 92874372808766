import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation"
import jp from "./locales/jp/translation"

const factory = (locale) => {
  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      jp: {
        translation: jp
      }
    },
    lng: locale,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

}

export default factory;

import baseTheme from "@chakra-ui/theme"

// custom styles
const Button = {
  ...baseTheme.components.Button,
  baseStyle: {
    ...baseTheme.components.Button.baseStyle,
    textTransform: 'uppercase',
    fontWeight: 500,
    borderRadius: '50px',
    body: `Montserrat, ${baseTheme.fonts.body}`
  }
};

const Link = {
  ...baseTheme.components.Link,
  baseStyle: {
    ...baseTheme.components.Link.baseStyle,
    textDecoration: 'underline'
  }
};

const Footer = {
  baseStyle: {
    wrapper: {
      bg: 'gray.100',
      color: 'black'
    },
    container: {
      textAlign: "center",
      py: 4
    }
  }
}

const StickyHeader = {
  register: {
    parts: ["wrapper", "container"],
    sizes: [],
    variants: [],
  },
  baseStyle: {
    wrapper: {
      bg: "gray.100",
      color: "black",
    },
    container: {
      py: 4
    },
  }
};

const FormLabel = {
  ...baseTheme.components.FormLabel,
  baseStyle: {
    ...baseTheme.components.FormLabel.baseStyle,
    fontSize: '11px',
    fontWeight: 600,
    textTransform: "uppercase"
  }
}

const Header = {
  register: {
    parts: ["wrapper", "container"],
    sizes: [],
    variants: [],
  },
  baseStyle: {
    wrapper: {
      bg: "transparent",
      color: "gray:900",
    },
    container: {
      py: 8
    },
  }
};


const themes = {
  ...baseTheme,
  fonts: {
    ...baseTheme.fonts,
    body: `Montserrat, ${baseTheme.fonts.body}`,
    heading: `Montserrat, ${baseTheme.fonts.heading}`
  },
  components: {
    ...baseTheme.components,
    Button,
    FormLabel,
    Link,
    Header,
    StickyHeader,
    Footer
  }
}

export default themes;

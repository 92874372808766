/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { I18nextProvider } from 'react-i18next';
import factory from './src/i18n';

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props

  const i18n = factory(pageContext.locale)
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}